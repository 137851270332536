<template>
  <span><font-awesome-icon v-if="infinite" :style="{ color: '#898989' }" icon="infinity" />{{ formatted }}</span>
</template>
<script>
export default {
  name: 'TimeLeft',
  props: [ 'months' ],
  computed: {
    infinite: function () {
      if (this.months < 0 || this.months === null) {
        return true
      } else {
        return false
      }
    },
    formatted: function () {
      if (this.months < 0 || this.months === null) {
        return ' years'
      } else if (this.months > 12) {
        return (this.months / 12).toFixed(2) + ' years'
      } else {
        return this.months + ' months'
      }
    }
  }
}
</script>
