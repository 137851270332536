<template>
  <b-row>
    <b-col sm="12">
      <b-row>
        <b-col sm="4">
          <b-card>
          <template slot="header">
            Inputs
          </template>
          <b-card-body>
            <table class="table b-table table-borderless table-sm" v-if="currentAnalysis && currentAnalysis.inputs">
              <tbody>
                <tr>
                  <th class="border-bottom">Monthly Amount:</th>
                  <td class="text-right border-bottom">
                    <dollars :value="currentAnalysis.inputs.monthly"></dollars>
                  </td>
                </tr>
                <tr>
                  <th>Balance to Use:</th>
                  <td class="text-right">
                    <dollars :value="currentAnalysis.inputs.cashValuesToUse + currentAnalysis.inputs.otherToUse"></dollars>
                  </td>
                </tr>
                <tr>
                  <th>Penalties:</th>
                  <td class="text-right">
                    - <dollars :value="currentAnalysis.inputs.penalties"></dollars>
                  </td>
                </tr>
                <tr>
                  <th>Taxes:</th>
                  <td class="text-right border-bottom">
                    - <dollars :value="currentAnalysis.inputs.taxes"></dollars>
                  </td>
                </tr>
                <tr>
                  <th>Total Available:</th>
                  <th class="text-right">
                    <dollars :value="currentAnalysis.inputs.balanceAvailable"></dollars>
                  </th>
                </tr>
              </tbody>
            </table>
          </b-card-body>
        </b-card>
        </b-col>
        <b-col sm="8">
          <b-card>
            <template slot="header">
              Debt Sequence
            </template>
            <b-card-body>
              <table class="table b-table table-drag-n-drop table-sm">
                <thead>
                  <tr>
                    <th aria-colindex="1" class=""></th>
                    <th aria-colindex="2" class="">Order</th>
                    <th aria-colindex="3" class="">Name</th>
                    <th aria-colindex="4" class="">Type</th>
                    <th aria-colindex="5" class="">Timeframe</th>
                    <th aria-colindex="6" class="numeric">Balance</th>
                    <th aria-colindex="7" class="numeric">Payment</th>
                    <th aria-colindex="8" class="numeric">Rate</th>
                    <th aria-colindex="9" class="numeric">Payoff Time</th>
                  </tr>
                </thead>
                <draggable tag="tbody" v-model="debts" @end="saveSort()">
                  <tr v-for="debt in debts" :key="debt.id" class="">
                    <td aria-colindex="1"><i class="fa fa-reorder"></i></td>
                    <td aria-colindex="2">{{ debt.sort }}</td>
                    <td aria-colindex="3" class="">{{ debt.name }}</td>
                    <td aria-colindex="4" class="">{{ debt.type }}</td>
                    <td aria-colindex="5" class=""><date :value="debt.startAt" :showDay="false" format="MM/YYYY" default="Existing"></date></td>
                    <td aria-colindex="6" class="numeric"><span><dollars :value="debt.balance"></dollars></span></td>
                    <td aria-colindex="7" class="numeric"><span><dollars :value="debt.payment"></dollars></span></td>
                    <td aria-colindex="8" class="numeric"><span><percent :value="debt.rate"></percent></span></td>
                    <td aria-colindex="9" class="numeric">
                      <time-left :months="debt.monthsToZero"></time-left>
                    </td>
                  </tr>
                </draggable>
              </table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-alert :show="!currentAnalysis.sustainable" variant="danger">
        <strong>Error</strong> This strategy is not sustainable.  Input cash/monthly payments are not enough to pay first year premiums.
      </b-alert> -->
      <div>
        <b-row v-for="yearAnalysis in currentAnalysis.annualSummary" :key="yearAnalysis.year">
          <b-col sm="12">
            <b-card>
              <template slot="header">
                Year {{ yearAnalysis.yearNum }} <small>(<date :value="yearAnalysis.startDate" format="MM/DD/YYYY" /> to <date :value="yearAnalysis.endDate" format="MM/DD/YYYY" />)</small>
              </template>
              <b-row>
                <b-col sm="4">
                  <table class="table b-table table-borderless table-sm">
                    <tbody>
                      <tr>
                        <td>Liquidated Assets</td>
                        <td class="text-right"><dollars :value="yearAnalysis.summary.liquidatedAssets"></dollars></td>
                      </tr>
                      <tr>
                        <td>Misc Account</td>
                        <td class="text-right">+ <dollars :value="yearAnalysis.summary.miscAccount"></dollars></td>
                      </tr>
                      <tr>
                        <td>End of Year Cash Value</td>
                        <td class="text-right">+ <dollars :value="yearAnalysis.summary.endOfYearCashValue"></dollars></td>
                      </tr>
                      <tr>
                        <td><strong>Total Current Cash</strong></td>
                        <td class="text-right border-top">
                          <strong><dollars :value="yearAnalysis.summary.totalCurrentCash"></dollars></strong>
                        </td>
                      </tr>
                      <tr v-if="yearAnalysis.summary.additionalCashNeeded > 0" class="warning">
                        <td>Cash Needed <i class="fa fa-info-circle" v-b-tooltip.hover :title="cashNeededTooltip(yearAnalysis.summary)"></i></td>
                        <td class="text-right">+ <dollars :value="yearAnalysis.summary.additionalCashNeeded" v-b-tooltip.disabled="true" v-b-tooltip.hover :title="cashNeededTooltip(yearAnalysis.summary)"></dollars></td>
                      </tr>
                      <tr>
                        <td>Annual Premiums</td>
                        <td class="text-right">- <dollars :value="yearAnalysis.summary.annualPremiums"></dollars></td>
                      </tr>
                      <tr>
                        <td><strong>Available Cash After Premium</strong></td>
                        <td class="text-right border-top">
                          <strong><dollars :value="yearAnalysis.summary.totalAfterPremiums"></dollars></strong>
                        </td>
                      </tr>
                      <tr v-if="yearAnalysis.summary.unusedExistingCashValue > 0">
                        <td>Existing Policy Unused Cash Value</td>
                        <td class="text-right">+ <dollars :value="yearAnalysis.summary.unusedExistingCashValue"></dollars></td>
                      </tr>
                      <tr>
                        <td>Additional Policy Cash Value</td>
                        <td class="text-right">+ <dollars :value="yearAnalysis.summary.additionalCashValue"></dollars></td>
                      </tr>
                      <tr v-if="yearAnalysis.summary.prepaidInterest > 0">
                        <td>Total Prepaid Interest</td>
                        <td class="text-right">
                          - <dollars :value="yearAnalysis.summary.prepaidInterest"></dollars>
                        </td>
                      </tr>
                      <tr v-if="yearAnalysis.summary.interest > 0">
                        <td>Total Interest</td>
                        <td class="text-right">
                          - <dollars :value="yearAnalysis.summary.interest"></dollars>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Total Available Cash for Debt</strong></td>
                        <td class="text-right border-top">
                          <strong><dollars :value="yearAnalysis.summary.totalAvailableForDebt"></dollars></strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col sm="8">
                  <table class="table b-table table-sm">
                    <thead>
                      <tr>
                        <th>Debts</th>
                        <th class="text-right">Starting Balance</th>
                        <th class="text-right">Interest</th>
                        <th class="text-right">Payments</th>
                        <th class="text-right">Ending Balance</th>
                        <th class="text-right">Interest Saved</th>
                        <th class="text-right">Pay Off Month</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="debt in yearAnalysis.debts" :key="debt.id" v-bind:class="{ paidOff: debt.endingBalance === 0, lateStart: debt.startMonth > 0 }" v-b-tooltip.hover :title="debtTooltip(debt)">
                        <td>{{ debt.name }} <i v-if="debt.startMonth > 0" class="fa fa-info-circle"></i></td>
                        <td class="text-right"><dollars :value="debt.startingBalance"></dollars></td>
                        <td class="text-right"><dollars :value="debt.interest"></dollars></td>
                        <td class="text-right"><dollars :value="debt.payments"></dollars></td>
                        <td class="text-right"><dollars :value="debt.endingBalance"></dollars></td>
                        <td class="text-right"><dollars :value="debt.interestSaved"></dollars></td>
                        <td class="text-right">{{ debt.paidOffMonth }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col sm="12">
                  <table class="table b-table table-sm">
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th></th>
                        <th class="text-right">Payment</th>
                        <th class="text-right">Wind Current Change</th>
                        <th class="text-right">Total Paid</th>
                        <th v-if="yearAnalysis.ledger.cashNeeded" class="text-right">Additional Cash Needed</th>
                        <th class="text-right">Premium Payment</th>
                        <th class="text-right">Principle</th>
                        <th class="text-right">Policy Loan</th>
                        <th class="text-right">Misc Account</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :class="{ 'windCurrentAlert' : yearAnalysis.ledger.windCurrent.increase > 0 }">
                        <td>Start</td>
                        <td></td>
                        <td class="text-right"><dollars :value="yearAnalysis.ledger.windCurrent.startingAmount"></dollars></td>
                        <td class="text-right"><dollars v-if="yearAnalysis.ledger.windCurrent.increase > 0" :value="yearAnalysis.ledger.windCurrent.increase"></dollars></td>
                        <td class="text-right"></td>
                        <td v-if="yearAnalysis.ledger.cashNeeded" class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right"><dollars :value="yearAnalysis.ledger.policyLoan.startingBalance"></dollars></td>
                        <td class="text-right"><dollars :value="yearAnalysis.ledger.miscAccount.startingBalance"></dollars></td>
                      </tr>
                      <tr v-for="payment in yearAnalysis.ledger.payments" :key="payment.monthNum" :class="{ 'windCurrentAlert' : payment.windCurrent > 0 }">
                        <td>{{ payment.monthNum }}</td>
                        <td><date :value="payment.date" format="MM/YYYY" /></td>
                        <td class="text-right"><dollars :value="payment.payment"></dollars></td>
                        <td class="text-right"><dollars v-if="payment.windCurrent !== 0" :value="payment.windCurrent" :class="{ 'warning' : payment.windCurrent < 0 }"></dollars></td>
                        <td class="text-right"><dollars :value="payment.totalPaid"></dollars></td>
                        <td v-if="yearAnalysis.ledger.cashNeeded" class="text-right"><dollars :value="payment.additionalCashNeeded"></dollars></td>
                        <td class="text-right"><dollars :value="payment.premium"></dollars></td>
                        <td class="text-right"><dollars :value="payment.principle"></dollars></td>
                        <td class="text-right"><dollars :value="payment.policyLoan"></dollars></td>
                        <td class="text-right"><dollars :value="payment.miscAccount"></dollars></td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Date from '@/components/text/Date'
import Dollars from '@/components/text/Dollars'
import Percent from '@/components/text/Percent'
import draggable from 'vuedraggable'
import TimeLeft from '@/components/text/TimeLeft'

export default {
  name: 'Strategy',
  components: {
    Date,
    Dollars,
    Percent,
    draggable,
    TimeLeft
  },
  data: () => {
    return {
      debts: [],
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'balance', label: 'Balance', class: 'numeric' },
        { key: 'payment', class: 'numeric' },
        { key: 'rate', class: 'numeric' },
        { key: 'monthsToZero', label: 'Payoff Time', class: 'numeric'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'global/currentClient',
      currentStrategy: 'global/currentStrategy',
      currentAnalysis: 'global/currentAnalysis'
    }),
    totalMonthly () {
      let totalMonthly = 0.0
      if (this.currentStrategy && this.currentStrategy.inputs) {
        this.currentStrategy.inputs.forEach((input) => {
          if (input.use) {
            totalMonthly += parseFloat(input.monthlyAmount)
          }
        })
      }
      return totalMonthly
    },
    totalLiquid () {
      let totalLiquid = 0.0
      let totalBalance = 0.0
      let totalPenalties = 0.0
      let totalReservedTaxes = 0.0
      this.currentStrategy.inputs.forEach((input) => {
        if (input.use) {
          totalBalance += parseFloat(input.balanceToUse)

          if (input.category.penalty) {
            totalPenalties += (parseFloat(input.balanceToUse) * 0.10)
          }

          if (input.category.taxable) {
            totalReservedTaxes += (parseFloat(input.balanceToUse) * (this.currentStrategy.taxRate / 100))
          }
        }
      })
      totalLiquid = totalBalance - totalPenalties - totalReservedTaxes
      return totalLiquid
    },
    totalBalance () {
      let totalBalance = 0.0
      return totalBalance
    },
    totalPenalties () {
      let totalPenalties = 0.0
      return totalPenalties
    },
    totalReservedTaxes () {
      let totalReservedTaxes = 0.0
        return totalReservedTaxes
    }
  },
  methods: {
    mapDebts (debts) {
      this.debts = []
      if (debts) {
        debts.forEach((debt) => {
          this.debts.push(debt)
        })
      }
    },
    debtTooltip (debt) {
      if (debt.startMonth > 0) {
        return `This debt starts in month ${debt.startMonth}`
      }
      return null
    },
    saveSort () {
      this.$store.dispatch('global/saveStrategy', {
        id: this.currentStrategy.id,
        debts: this.debts
      })
    },
    cashNeededTooltip (summary) {
      let tip = null

      if (summary.additionalCashNeeded > 0) {
        if (summary.loanForwardPrepaidInterest > 0 || summary.endOfYearCashPrepaidInterest > 0) {
          let interest = Number((summary.loanForwardPrepaidInterest + summary.endOfYearCashPrepaidInterest).toFixed(2))
          tip = `Additional cash required to pay premium + prepaid interest ($${interest})`
        } else {
          tip = 'Additional cash required to pay premium'
        }
      }
      return tip
    }
  },
  watch: {
    'currentStrategy.debts': {
      handler (debts) {
        this.mapDebts(debts)
        this.$store.dispatch('global/getAnalysis', {
          id: this.$route.params.strategyId
        })
      }
    }
  },
  created () {
    if (this.currentClient.id != this.$route.params.id) {
      this.$store.dispatch('global/getClient', {
        id: this.$route.params.id
      })
    }
    if (this.currentStrategy.id != this.$route.params.strategyId) {
      this.$store.dispatch('global/getStrategy', {
        id: this.$route.params.strategyId
      })
    }

    this.$store.dispatch('global/getAnalysis', {
      id: this.$route.params.strategyId
    })

    if (this.currentStrategy.debts) {
      this.mapDebts(this.currentStrategy.debts)
    }
  }
}
</script>

<style>
  div.card-body {
    padding: 0.75em 1.25em 0.75em 1.25em;
  }
  table.table-sm td.numeric, table.table-sm th.numeric {
    text-align: right;
  }
  .table-sm td.delete {
    width: 28px;
  }
  .table-sm td {
    vertical-align: middle;
  }
  .table-sm td.numeric, .table-sm th.numeric {
    text-align: right;
  }
  .table-sm tr.lateStart {
    background-color: #6a8fa5;
    color: white;
  }
  .table-sm tr.paidOff {
    background-color: #4dbd74;
    color: white;
  }
  .table-sm tr.windCurrentAlert {
    background-color: #4dbd74;
    color: white;
  }
  .table-sm tr.warning td {
    color: red;
  }
  span.warning {
    color: red;
  }
</style>
